<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import clientsView from "../../../components/clients/clientsView.vue";

/**
 * Starter component
 */
export default {
    page: {
        title: "Members",
        meta: [{ name: "description", content: appConfig.description }],
    },
    //eslint-disable-next-line
    components: { Layout, PageHeader, clientsView },
    data() {
        return {
            title: "Members",
            items: [
                {
                    text: "Members",
                    active: true,
                },
            ],
        };
    },
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <clientsView></clientsView>

        <!-- <p>jjj</p> -->
    </Layout>
</template>
