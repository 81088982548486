<template>
    <!-- <div v-if="hasMounted"> -->
    <div>
        <div v-if="client_users.length > 0">
            <webix-ui :config="ui" v-model="client_users"></webix-ui>
            <div id="clientUsersPaging"></div>
        </div>
        <h3 v-else class="text-center">This client has no client users yet</h3>

        <clientUsersEditModal v-if="show_modal" />
    </div>

    <!-- </div> -->
</template>

<script>
import Swal from "sweetalert2";
import { adminMethods, clientMethods, clientComputed } from "@/state/helpers";
import store from "@/state/store";
import clientUsersEditModal from "./modals/clientUsersEditModal.vue";

export default {
    components: { clientUsersEditModal },
    data: () => ({
        show_modal: false,
        hasMounted: false,
        someData: [{ name: "someData" }],
        ui: {
            id: "clientUsersTable",
            view: "datatable",
            css: "datatableUsers webix_header_border webix_data_border",
            autoheight: true,
            //hover: "datatableHover",
            minHeight: 60,
            minColumnWidth: 120,
            rowHeight: 40,
            scrollY: false,
            scrollX: true,
            resizeColumn: true,
            select: false,
            pager: {
                template: "{common.prev()} {common.pages()} {common.next()}",
                container: "clientUsersPaging",
                css: { width: "100% !important" },
                size: 15,
                group: 5,
            },
            columns: [
                // {
                //     id: "actions",
                //     header: [
                //         {
                //             text: "Actions",
                //             rowspan: "2",
                //             css: { "text-align": "center" },
                //         },
                //     ],
                //     fillspace: 0.5,
                //     template: function () {
                //         return '<div class="tableIcons"><i class=" mdi mdi-trash-can removeClientUserAction "</i>&nbsp;</i><i class="mdi mdi-account-edit editClientUserAction"></i></div>';
                //     },
                // },
                {
                    id: "name",
                    header: ["name", { content: "textFilter" }],
                    tooltip: false,
                    fillspace: 1,
                },
                {
                    id: "email",
                    header: ["Email", { content: "textFilter" }],
                    fillspace: 0.8,
                },
                {
                    id: "role_name",
                    header: ["Display name", { content: "selectFilter" }],
                    fillspace: 0.8,
                },
            ],
            on: {},
            onClick: {
                //eslint-disable-next-line
                editClientUserAction: function (id, e, trg) {
                    const el = this.$scope.$parent;

                    el.setSelectedUserToEdit(this.getItem(e.row));

                    el.showEditUserModal();
                },
                //eslint-disable-next-line
                removeClientUserAction: function (id, e, trg) {
                    //eslint-disable-next-line
                    const el = this.$scope.$parent;
                    //item will contain all the tables row data and other junk
                    const item = this.getItem(e.row);

                    Swal.fire({
                        title: `Remove "${item.name}" from "${el.selected_client_details.company_name}"`,
                        text: "You won't be able to undo this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Yes, remove!",
                    }).then((result) => {
                        if (result.value) {
                            el.deleteClientUser({ id: e.row }).then(() => {
                                Swal.fire("The Client user was deleted", "", "success");

                                //will refresh the table on screen
                                el.setClientUsers(el.$route.params.clientid);
                            });
                        }
                    });
                },
            },
        },
    }),
    computed: {
        ...clientComputed,
    },
    // watch: {
    //     hasMounted() {
    //         setTimeout(() => {
    //             window.webix.$$("clientUsersTable").refresh();
    //             // window.webix.$$("clientUsersPaging").refresh();
    //         }, 0);
    //     },
    // },
    methods: {
        ...adminMethods,
        ...clientMethods,
        showEditUserModal() {
            this.show_modal = true;
            this.$nextTick(() => {
                this.$bvModal.show("clientUsersEditModal");
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "clientUsersEditModal") {
                        this.show_modal = false;
                        this.edit_id = -1;
                    }
                });
            });
        },
    },
    beforeMount() {
        this.ui.columns.unshift({
            id: "actions",
            header: [
                {
                    text: "Actions",
                    rowspan: "2",
                    css: { "text-align": "center" },
                },
            ],
            fillspace: 0.4,
            template: () => {
                const userRole = store.getters["admin/getUserRole"];

                //1 = admin
                if (userRole == "admin") {
                    return `
                    <div class="tableIcons">
                        <i class=" mdi mdi-trash-can removeClientUserAction "></i>
                        <i class="mdi mdi-account-edit editClientUserAction"></i>
                        &nbsp;
                        </div>`;
                }

                return '<div class="tableIcons"><i class="mdi mdi-eye-plus viewAction"></i></div>';
            },
        });
    },
    mounted() {
        // window.clientUsersTable = this;
        // this.hasMounted = true;

        // if (this.client_users.length == 0) {
        //     this.setClientUsers(this.$route.params.clientid).then(() => {
        //         //
        //     });
        // } else {
        //     this.setClientUsers(this.$route.params.clientid);
        // }
        this.setClientUsers(this.$route.params.clientid);
    },
};
</script>

<style></style>
