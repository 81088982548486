<template>
    <b-modal title="Edit Client User" id="clientUsersEditModal" hide-footer>
        <b-overlay :show="busySaving">
            <!-- form -->
            <b-form @submit.stop.prevent="onSubmit">
                <b-form-group label="Name" label-for="example-input-1">
                    <b-form-input
                        id="example-input-1"
                        name="example-input-1"
                        v-model="$v.form.name.$model"
                        :state="validateState('name')"
                        aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field and must be at least 3 characters.</b-form-invalid-feedback
                    >
                </b-form-group>

                <b-form-group label="Email" label-for="example-input-1">
                    <b-form-input
                        id="example-input-1"
                        name="example-input-1"
                        v-model="$v.form.email.$model"
                        :state="validateState('email')"
                        aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback">Please enter a valid email</b-form-invalid-feedback>
                </b-form-group>

                <b-form-checkbox id="checkbox-1" @change="toggleEnableInput" name="checkbox-1" class="mt-4 mb-2 ml-1" switch>
                    Change password
                </b-form-checkbox>
                <b-form-group v-if="!disabledPassword" id="example-input-group-1" label="Password" label-for="example-input-1" class="mt-3">
                    <!-- <b-form-input :disabled="disabledPassword" type="password" id="example-input-1" name="example-input-1"></b-form-input> -->
                    <b-form-input type="password" v-model="$v.form.password.$model" :state="validateState('password')"></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback"
                        >Please enter a valid password, also needs to be atleast 8 characters long</b-form-invalid-feedback
                    >
                </b-form-group>

                <!-- footer -->
                <div class="modal-footer d-flex justify-content-between mt-3">
                    <b-button type="submit" variant="primary">Update user</b-button>
                    <button type="button" class="btn btn-secondary" @click="closeModal()">Close</button>
                </div>
            </b-form>
        </b-overlay>
    </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
import { adminMethods, adminComputed, clientMethods } from "@/state/helpers";
//eslint-disable-next-line
import Swal from "sweetalert2";

export default {
    mixins: [validationMixin],
    data() {
        return {
            disabledPassword: true,
            current_branch: -1,
            formSubmit: false,
            busySaving: false,
            form: {
                id: null,
                name: null,
                email: null,
                password: null,
                role_id: 0,
            },
        };
    },
    validations: {
        form: {
            email: {
                required,
                email,
            },
            name: {
                required,
                minLength: minLength(3),
            },
            password: {
                minLength: minLength(8),
            },
        },
    },
    computed: {
        ...adminComputed,
        branchName() {
            if (this.selected_branch_id == 1) {
                return "Cape Town";
            } else {
                return "Johannesburg";
            }
        },
    },
    methods: {
        ...adminMethods,
        ...clientMethods,
        toggleEnableInput() {
            this.$nextTick(() => {
                this.disabledPassword = !this.disabledPassword;
            });
        },
        validateState(name) {
            if (this.formSubmit) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            }
        },
        resetForm() {
            this.form = {
                name: null,
                role_id: null,
                email: null,
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },

        onSubmit() {
            this.formSubmit = true;

            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.busySaving = true;

            Swal.fire({
                title: `Update user details: ${this.form.name}`,
                text: "Are you sure you would like to update this users details",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, update!",
            }).then((result) => {
                if (result.value) {
                    this.updateUser(this.form).then(() => {
                        this.setClientUsers(this.$route.params.clientid);
                        Swal.fire(`${this.form.name} was edited.`, `User updated`, "success");
                        this.busySaving = false;
                        this.closeModal();
                    });
                } else {
                    this.busySaving = false;
                    this.closeModal();
                }
            });
        },
        closeModal() {
            this.$bvModal.hide("clientUsersEditModal");
        },
    },
    mounted() {
        this.form.name = this.selected_userToEdit.name;
        this.form.email = this.selected_userToEdit.email;
        this.form.id = this.selected_userToEdit.id;
    },
};
</script>
<style>
input[readonly] {
    background-color: red;
}
</style>
