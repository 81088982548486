<template>
    <b-modal title="Create a client user" id="clientUsersAddModal" hide-footer>
        <b-overlay :show="busySaving">
            <!-- form -->
            <b-form @submit.stop.prevent="onSubmit">
                <b-form-group id="example-input-group-1" label="Name" label-for="example-input-1">
                    <b-form-input
                        id="example-input-1"
                        name="example-input-1"
                        v-model="$v.form.name.$model"
                        :state="validateState('name')"
                        aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required field and must be at least 3 characters.</b-form-invalid-feedback
                    >
                </b-form-group>

                <b-form-group id="example-input-group-1" label="Email" label-for="example-input-1">
                    <b-form-input
                        id="example-input-1"
                        name="example-input-1"
                        v-model="$v.form.email.$model"
                        :state="validateState('email')"
                        aria-describedby="input-1-live-feedback"
                    ></b-form-input>

                    <b-form-invalid-feedback id="input-1-live-feedback">Please enter a valid email</b-form-invalid-feedback>
                </b-form-group>
                <div class="modal-footer">
                    <b-button type="submit" variant="primary">Create client user</b-button>
                    <button type="button" class="btn btn-secondary" @click="closeModal()">Close</button>
                </div>
            </b-form>
        </b-overlay>
    </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
//eslint-disable-next-line
import { required, email, minLength } from "vuelidate/lib/validators";
import { clientMethods, clientComputed } from "@/state/helpers";
import Swal from "sweetalert2";

export default {
    mixins: [validationMixin],
    data() {
        return {
            formSubmit: false,
            busySaving: false,
            form: {
                client_id: null,
                name: null,
                email: null,
            },
        };
    },
    validations: {
        form: {
            email: {
                required,
                email,
            },
            name: {
                required,
                minLength: minLength(3),
            },
        },
    },
    computed: { ...clientComputed },
    methods: {
        ...clientMethods,
        validateState(name) {
            if (this.formSubmit) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            }
        },
        onSubmit() {
            this.formSubmit = true;

            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.busySaving = true;

            this.createClientUser(this.form).then(() => {
                this.getClients().then(() => {
                    this.busySaving = false;
                    this.$bvModal.hide("clientUsersAddModal");
                    Swal.fire(`${this.form.name} was added to the client`, `Client updated`, "success");
                });
                this.setClientUsers(this.$route.params.clientid);
            });
        },
        closeModal() {
            this.$bvModal.hide("clientUsersAddModal");
        },
    },
    mounted() {
        this.form.client_id = this.selected_client_id;
    },
};
</script>

<style lang="scss" scoped></style>
