<template>
  <b-overlay :show="busySaving">
    <b-container fluid>
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col v-for="(question) in questions" :key="question.id" md="6">
            <b-form-group>
              <template v-if="question.type === 'input'">
                <label :for="`question-${question.id}`">{{ question.body }}</label>
                <b-form-input :id="`question-${question.id}`" v-model="answers[question.id]"></b-form-input>
              </template>

              <template v-if="question.type === 'number'">
                <label :for="`question-${question.id}`">{{ question.body }}</label>
                <b-form-input type="number" :id="`question-${question.id}`" v-model="answers[question.id]"></b-form-input>
              </template>

              <template v-else-if="question.type === 'textarea'">
                <label :for="`question-${question.id}`">{{ question.body }}</label>
                <b-form-textarea :id="`question-${question.id}`" v-model="answers[question.id]" rows="3"></b-form-textarea>
              </template>

              <template v-else-if="question.type === 'radio'">
                <label>{{ question.body }}</label>
                <b-form-radio-group :options="question.options.map(option => ({ text: option.value, value: option.value }))"
                v-model="answers[question.id]"></b-form-radio-group>
              </template>

              <template v-else-if="question.type === 'multiselect'">
                <label>{{ question.body }}</label>
                <b-form-select :options="question.options.map(option => ({ text: option.value, value: option.value }))"
                  v-model="answers[question.id]" multiple
                  :select-size="5"></b-form-select>
              </template>

              <template v-else-if="question.type === 'select'">
                <label>{{ question.body }}</label>
                <b-form-select :options="question.options.map(option => ({ text: option.value, value: option.value }))"
                  v-model="answers[question.id]"
                  :select-size="5"></b-form-select>
              </template>
            </b-form-group>
          </b-col>
        </b-row>

        <b-button type="submit" variant="primary">Submit</b-button>
      </b-form>
    </b-container>
  </b-overlay>
</template>
<script>
import Swal from "sweetalert2";
import { clientMethods, clientComputed } from "@/state/helpers";
export default {
  data: () => ({
    answers: {},
    busySaving: false 
  }),
  watch: {
    questions: {
      immediate: true,
      handler(newQuestions) {
        if (newQuestions) {
          this.hydrateAnswers(newQuestions);
        }
      },
    },
  },
  computed: {
    ...clientComputed,
    clientDetails() {
      return this.selected_client_details;
    },
    questions() {
      return this.clientDetails.member_questions;
    }
  },
  methods: {
    ...clientMethods,
    hydrateAnswers(questions) {
      // Initialize answers object based on questions from Vuex store
      const initialAnswers = {};
      questions.forEach(question => {
        if (question.answers && question.answers.length > 0) {
          // Assuming the first answer in the array is the one to edit
          const answerValue = question.type === 'multiselect' ? question.answers[0].value.split(',') : question.answers[0].value;
          initialAnswers[question.id] = answerValue;
        } else {
          // Set default values based on question type
          initialAnswers[question.id] = question.type === 'multiselect' ? [] : '';
        }
      });
      this.answers = initialAnswers;
    },
    /*getAnswerValue(questionId) {
      // Retrieve the answer value from the local answers state
      return this.answers[questionId] || '';
    },
    updateAnswer(questionId, value) {
      // Update the local answers state with new value
      this.$set(this.answers, questionId, value);
    },*/
    onSubmit() {
      this.busySaving = true;
      this.saveProfileQuestionAnswers({answers: this.answers}).then(() => {
        this.busySaving = false;
        Swal.fire("Your CannaProfile was updated!", "", "success");
        this.$emit('refresh');
      });
    }
  },
  created() {
    //this.initializeAnswers();
  },
};
</script>