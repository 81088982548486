<template>
    <div>
        <simpleLayout v-if="true" :items_count="5">
            <template #buttons>
                <div>
                    <button type="button" class="btn btn-success mb-2 mr-2" @click="addClient"><i class="mdi mdi-plus mr-1"> </i> Add Member</button>
                    <button v-show="clients.length > 0" type="button" class="btn btn-success mb-2 mr-2" @click="exportToExcel('clientsTable')">
                        <i class="mdi mdi-file-excel mr-1"></i> Export to Excel
                    </button>
                </div>
            </template>
            <template #table>
                <clientsTable />
            </template>
        </simpleLayout>
        <clientModal v-if="show_modal" />
        <!-- made false -->
        <div v-if="false">
            <p>jip"selected_client_id</p>
            <clientDetails />
        </div>
    </div>
</template>

<script>
import simpleLayout from "@/components/layout-templates/simple-layout.vue";
import clientsTable from "./clientsTable.vue";
import clientDetails from "./clientDetails";
import clientModal from "./clientModal.vue";
import _ from "lodash";

import { clientMethods, clientComputed } from "@/state/helpers";

export default {
    //eslint-disable-next-line
    components: { simpleLayout, clientsTable, clientDetails, clientModal },
    data: () => ({
        show_modal: false,
    }),
    computed: {
        ...clientComputed,
        hasClientSelected() {
            return this.selected_client_id != -1;
        },
    },
    methods: {
        ...clientMethods,
        exportToExcel(id) {
            /* eslint-disable */
            return webix.toExcel($$(id), {
                ignore: { actions: true },
                filename: _.uniqueId("clientsExport_"), // for filename
                name: "Clients", // for sheet name
            });
            /* eslint-enable */
        },
        addClient() {
            this.show_modal = true;
            this.$nextTick(() => {
                this.$bvModal.show("clientModal");
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "clientModal") {
                        this.show_modal = false;
                    }
                });
            });
        },
    },
    mounted() {
        //ensures if user nvaigated to another page that the id reset, else clientDetailsPage will be rendered
        this.setSelectedClientId(-1);
    },
};
</script>

<style lang="scss" scoped></style>
