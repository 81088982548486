<template>
    <div v-if="selected_client_details">
        <div v-if="selected_client_orders.length > 0">
            <webix-ui :config="ui" v-model="selected_client_details.orders"></webix-ui>
            <div id="clientOrderPaging"></div>
        </div>
        <h3 v-else class="text-center">There no orders yet</h3>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import store from "@/state/store";
import { adminComputed, clientMethods, clientComputed } from "@/state/helpers";

export default {
    data: () => ({
        show_modal: false,
        ui: {
            id: "ordersTable",
            view: "datatable",
            css: "datatableUsers webix_header_border webix_data_border",
            autoheight: true,
            //hover: "datatableHover",
            minHeight: 60,
            minColumnWidth: 120,
            rowHeight: 40,
            scrollY: false,
            scrollX: true,
            resizeColumn: true,
            select: false,
            pager: {
                template: "{common.prev()} {common.pages()} {common.next()}",
                container: "clientOrderPaging",
                css: { width: "100% !important" },
                size: 15,
                group: 5,
            },
            columns: [
                {
                    id: "order_no",
                    header: ["Order Number", { content: "textFilter" }],
                    tooltip: false,
                    fillspace: 1,
                },
                {
                    id: "due_date",
                    header: ["Due Date", { content: "textFilter" }],
                    fillspace: 0.8,
                },
                {
                    id: "status_id",
                    header: ["Status", { content: "selectFilter" }],
                    fillspace: 0.8,
                    template: (value) => {
                        //eslint-disable-next-line
                        //this endpoint only returns an integer and not the name of the order status
                        if (value.status_id == 1) return "Received";
                        if (value.status_id == 2) return "Confirmed";
                        if (value.status_id == 3) return "Processing";
                        if (value.status_id == 4) return "Awaiting Courier Collection";
                        if (value.status_id == 5) return "Dispatched";
                    },
                },

                {
                    id: "contact_email",
                    header: ["Email", { content: "textFilter" }],
                    fillspace: 1,
                },
            ],
            on: {},
            onClick: {
                //eslint-disable-next-line
                viewAction: function (id, e, trg) {
                    let el = this.$scope.$parent;
                    let idForUrl = e.row;

                    el.$router.push({
                        path: `/client/view/order/${idForUrl}`,
                        param: { idForUrl },
                    });
                },
                //eslint-disable-next-line
                deleteAction: function (id, e, trg) {
                    const el = this.$scope.$parent;
                    //item will contain all the tables row data and other junk
                    const item = this.getItem(e.row);

                    //find the order in the orders array, webix keeps the the entire object that was returned from the vuex store
                    let f = el.selected_client_details.orders.find((o) => {
                        return o.id == item.id;
                    });

                    const requestObject = {
                        order_id: f.id,
                    };

                    Swal.fire({
                        title: `Remove order: ${f.order_no}`,
                        text: "You won't be able to undo this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Yes, remove!",
                    }).then((result) => {
                        if (result.value) {
                            el.deleteClientOrder(requestObject).then(() => {
                                Swal.fire("The order was deleted", "", "success");
                                let params;
                                if (el.repUser) {
                                    params = {
                                        client_id: el.$route.params.clientid,
                                        rep_id: el.selected_rep_id
                                    };
                                } else {
                                    params = {
                                        client_id: el.$route.params.clientid
                                    };
                                }
                                el.setSelectedClientDetails(params);
                            });
                        }
                    });
                },
            },
        },
    }),
    props: {
        repUser: Boolean
    },
    computed: {
        ...adminComputed,
        ...clientComputed,
    },
    methods: {
        ...clientMethods,
    },
    beforeMount() {
        this.ui.columns.unshift({
            id: "actions",
            header: [
                {
                    text: "Actions",
                    rowspan: "2",
                    css: { "text-align": "center" },
                },
            ],
            fillspace: 0.5,
            template: () => {
                const userRole = store.getters["admin/getUserRole"];

                if (userRole == "admin") {
                    return `<div class="tableIcons">
                            <i class="mdi mdi-trash-can deleteAction"></i>
                            &nbsp;
                            <i class="mdi mdi-eye viewAction"></i>
                            </div>`;
                }

                return '<div class="tableIcons"><i class="mdi mdi-eye-plus viewAction"></i></div>';
            },
        });
    },
    mounted() {},
    beforeCreate() {
        // window.inst = this;
    },
    beforeDestroy() {
        // window.inst = undefined;
    },
};
</script>

<style lang="scss" scoped></style>
